<template>
    <div class="wrap-paymax">
        <CCard>
            <CCardBody>
                <ValidationObserver ref="observer" @submit.prevent="handleSave">
                    <CForm>
                        <ValidationProvider :name="$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_PLATFORM')" rules="required" v-slot="{ errors }">
                            <div>
                                <CInput
                                        :label="$t('EDIT_PAYOUT_MAX_PAGE_LABEL_PLATFORM')"
                                        :placeholder="$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_PLATFORM')"
                                        horizontal
                                        v-model="payout.platform"
                                        class="form-group--custom"
                                        disabled
                                />
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider :name="$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_TOKEN_NAME')" rules="required" v-slot="{ errors }">
                            <div>
                                <CInput
                                        :label="$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_NAME')"
                                        :placeholder="$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_NAME')"
                                        horizontal
                                        v-model="payout.token_name"
                                        class="form-group--custom"
                                        disabled
                                />
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider :name="$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_TOKEN_SYMBOL')" rules="required" v-slot="{ errors }">
                            <div >
                                <CInput
                                        :label="$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_SYMBOL')"
                                        :placeholder="$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_SYMBOL')"
                                        horizontal
                                        v-model="payout.token_symbol"
                                       
                                        class="form-group--custom"
                                        disabled
                                />
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider :name="$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_TOKEN_ADDRESS')" rules="required" v-slot="{ errors }">
                            <div>
                                <CInput
                                        :label="$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_ADDRESS')"
                                        :placeholder="$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_ADDRESS')"
                                        horizontal
                                        v-model="payout.token_address"
                                        class="form-group--custom"
                                        disabled
                                />
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider :name="$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_MAX_PAYOUT')" rules="required" v-slot="{ errors }">
                            <div class="num-pay">
                                <CInput
                                        :label="$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_MAX_PAYOUT')"
                                        :placeholder="$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_MAX_PAYOUT')"
                                        type="number"
                                        horizontal
                                        v-model="payout.max_payout"
                                        class="form-group--custom"
                                />
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </div>
                        </ValidationProvider>
                        <div class="btn-pay">
                            <CButton type="submit">{{$t("EDIT_PAYOUT_MAX_PAGE_BUTTON_SAVE")}}</CButton>
                        </div>
                    </CForm>
                </ValidationObserver>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import Draggable from "vuedraggable";
    import endpoints from "@/constants/endpoints";
    import { mapState, mapActions } from "vuex";
    import platform from "../../../../../store/modules/platform";
    export default {
        components: {
            Draggable
        },
        data() {
            return {};
        },
        computed: {
            ...mapState({
                payout: state => state.platform.selectedPayout
            }),
        },
        async mounted() {

        },
        methods: {
            async handleSave () {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) return;
                let data;
                try{
                    data = {
                            max_payout: Number(this.payout.max_payout)
                    }
                    const { staking_platform_id, id } = this.payout;
                    const result = await this.$http.put(endpoints.updatePayoutmax(staking_platform_id), data);
                    console.log(result, 'sss');
                    if (result.data) {
                        this.$notify({
                            group: 'notifications',
                            type: 'error',
                            title: this.$t('EDIT_PAYOUT_MAX_PAGE_TITLE_NOTIFY_ERROR'),
                            text: this.$t('EDIT_PAYOUT_MAX_PAGE_TEXT_NOTIFY_ERROR')
                        });
                        return result.data,
                        this.$router.back();
                    }
                } catch (err) {
                    if(err){
                        this.$notify({
                            group: 'notifications',
                            type: 'error',
                            title: this.$t('EDIT_PAYOUT_MAX_PAGE_TITLE_NOTIFY_ERROR'),
                            text: this.$t('EDIT_PAYOUT_MAX_PAGE_TEXT_NOTIFY_ERROR')
                        });
                        this.$router.back();
                    }
                }
            }
        },
    };
</script>
<style lang="scss">
    .wrap-paymax{
        padding: 0 24px;
        .card{
            width: 50%;
            @media screen and (max-width: 1023px){
                width: 100%;
            }
            .num-pay{
                .form-group{
                    margin-bottom: 0;
                }
                input{
                    width: 25% !important;
                }
                .error-msg{
                    color: #ec0d0d;
                }
                .row{
                    .col-sm-9{
                        padding-left: 10px;
                        @media screen and (max-width: 576px){
                            padding-left: 15px;
                        }
                    }
                }
            }
            .form-group{
                
                label{
                    width: 25%;
                    text-align: right;
                    padding-right: 15px;
                    line-height: 25px;
                    color: #3e4b65;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: .5px;
                    @media screen and (max-width: 600px){
                        text-align: left;
                        width: 100%;
                    }
                }
                input{
                    width: 90%;
                    // @media screen and (max-width: 400px){
                    //     width: 60%;
                    // }
                }
            
            }
            .btn-pay{
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                padding-right: 50px;

                button[type="submit"]{
                    min-width: 146px;
                    height: 36px;
                    border-radius: 4px;
                    background-color: #3b4b7e;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: .5px;
                    border: none;
                    outline: none!important;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    text-align: center
                }
            }
            
        }
    }
    
</style>