var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap-paymax"},[_c('CCard',[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[_c('CForm',[_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_PLATFORM'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PAYOUT_MAX_PAGE_LABEL_PLATFORM'),"placeholder":_vm.$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_PLATFORM'),"horizontal":"","disabled":""},model:{value:(_vm.payout.platform),callback:function ($$v) {_vm.$set(_vm.payout, "platform", $$v)},expression:"payout.platform"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_TOKEN_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_NAME'),"placeholder":_vm.$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_NAME'),"horizontal":"","disabled":""},model:{value:(_vm.payout.token_name),callback:function ($$v) {_vm.$set(_vm.payout, "token_name", $$v)},expression:"payout.token_name"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_TOKEN_SYMBOL'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_SYMBOL'),"placeholder":_vm.$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_SYMBOL'),"horizontal":"","disabled":""},model:{value:(_vm.payout.token_symbol),callback:function ($$v) {_vm.$set(_vm.payout, "token_symbol", $$v)},expression:"payout.token_symbol"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_TOKEN_ADDRESS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_ADDRESS'),"placeholder":_vm.$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_ADDRESS'),"horizontal":"","disabled":""},model:{value:(_vm.payout.token_address),callback:function ($$v) {_vm.$set(_vm.payout, "token_address", $$v)},expression:"payout.token_address"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('EDIT_PAYOUT_MAX_PAGE_FIELD_NAME_VALIDATE_MAX_PAYOUT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"num-pay"},[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('EDIT_PAYOUT_MAX_PAGE_LABEL_TOKEN_MAX_PAYOUT'),"placeholder":_vm.$t('EDIT_PAYOUT_MAX_PAGE_PLACEHOLDER_TOKEN_MAX_PAYOUT'),"type":"number","horizontal":""},model:{value:(_vm.payout.max_payout),callback:function ($$v) {_vm.$set(_vm.payout, "max_payout", $$v)},expression:"payout.max_payout"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('div',{staticClass:"btn-pay"},[_c('CButton',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("EDIT_PAYOUT_MAX_PAGE_BUTTON_SAVE")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }